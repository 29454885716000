import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { firstValueFrom } from 'rxjs';
import { GenericException } from '../../shared/domain/entities/exceptions';
import {
  SecurityChallenge,
  SecurityChallengeChannel,
  SecurityChallengeException,
  SecurityChallengeScope,
} from '../domain/entities/security-challenge.entity';
import { SecurityChallengesRepositoryPort } from '../domain/ports/security-challenges.repository.port';

interface ChallengeDto {
  challengeId: string;
  type: 'one-time-password';
  scope: 'reset_password' | 'verify_email';
  channel: 'email';
  channelIdentifier: string;
  expiresAt: Date;
  canResentAt: Date;
}

@Injectable()
export class HttpSecurityChallengesRepositoryAdapter implements SecurityChallengesRepositoryPort {
  private readonly httpClient = inject(HttpClient);
  private readonly destroyRef = inject(DestroyRef);

  async takeChallenge(
    scope: SecurityChallengeScope,
    channel: SecurityChallengeChannel,
    channelIdentifier: string,
  ): Promise<SecurityChallenge> {
    const dto = await firstValueFrom(
      this.httpClient
        .post<ChallengeDto>(`api/users/take-challenge`, {
          scope,
          channel,
          channelIdentifier,
        })
        .pipe(takeUntilDestroyed(this.destroyRef)),
    );

    return new SecurityChallenge(
      dto.challengeId,
      dto.type,
      dto.scope,
      dto.channel,
      dto.channelIdentifier,
      dto.expiresAt,
      dto.canResentAt,
    );
  }

  async submitChallenge(
    challengeId: SecurityChallenge['id'],
    type: 'one-time-password',
    challengeSubmittal: string,
  ): Promise<{ grant: 'reset_password' | null }> {
    try {
      const response = await firstValueFrom(
        this.httpClient
          .post<{ grant: 'reset_password' | null }>(`api/users/submit-challenge`, {
            challengeId,
            type,
            challengeSubmittal,
          })
          .pipe(takeUntilDestroyed(this.destroyRef)),
      );

      return response;
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        switch (e.status) {
          case 404:
            throw new SecurityChallengeException('not-found');
          case 401:
            throw new SecurityChallengeException('invalid-code');
        }
      }

      throw new GenericException('unknown-error', e);
    }
  }
}
