import { isPlatformBrowser } from '@angular/common';
import { InjectionToken, PLATFORM_ID, Provider } from '@angular/core';
import { CurrentUserStateAdapter } from '@app/current-user/adapters/current-user.state.adapter';
import { HttpSecurityChallengesRepositoryAdapter } from '@app/current-user/adapters/http.security-challenges.repository.adapter';
import { HttpUsersRepositoryAdapter } from '@app/current-user/adapters/http.users.repository.adapter';
import { CurrentUserStatePort } from './current-user/domain/ports/current-user.state.port';
import { SecurityChallengesRepositoryPort } from './current-user/domain/ports/security-challenges.repository.port';
import { UsersRepositoryPort } from './current-user/domain/ports/users.repository.port';

export const APP_DOMAIN = new InjectionToken<string>('APP_DOMAIN');

export default () => {
  return [
    {
      provide: APP_DOMAIN,
      useFactory: (platform: string) => {
        let url = '';
        if (isPlatformBrowser(platform)) {
          const { protocol, host } = window.location;
          url = `${protocol}//${host}`;
        }

        return url;
      },
      deps: [PLATFORM_ID],
    },
    {
      provide: UsersRepositoryPort,
      useClass: HttpUsersRepositoryAdapter,
    },
    {
      provide: SecurityChallengesRepositoryPort,
      useClass: HttpSecurityChallengesRepositoryAdapter,
    },
    {
      provide: CurrentUserStatePort,
      useClass: CurrentUserStateAdapter,
    },
  ] as Provider[];
};
