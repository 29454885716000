import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { firstValueFrom } from 'rxjs';
import { GenericException } from '../../shared/domain/entities/exceptions';
import { Permission, UserEntity, UserException, UserProfile } from '../domain/entities/user.entity';
import { UsersRepositoryPort } from '../domain/ports/users.repository.port';

interface UserDto {
  id: string;
  email: string;
  displayName: string;
  lang: 'en';
  firstname?: string;
  lastname?: string;
  isEmailVerified: boolean;
  areCurrentTermsAccepted: boolean;
  permissions: Permission[];
}

export class HttpUsersRepositoryAdapter implements UsersRepositoryPort {
  private readonly http = inject(HttpClient);
  private readonly destroyRef = inject(DestroyRef);

  async getCurrentUser(): Promise<UserEntity> {
    const dto = await firstValueFrom(this.http.get<UserDto>('api/users/current', {}));

    if (!dto) {
      throw new UserException('user-not-found');
    }

    return new UserEntity(
      dto.id,
      dto.email,
      dto.displayName,
      dto.lang,
      dto.isEmailVerified,
      dto.areCurrentTermsAccepted,
      dto.permissions,
      dto.firstname,
      dto.lastname,
    );
  }

  async signOut(): Promise<void> {
    await firstValueFrom(this.http.post('api/users/sign-out', null).pipe(takeUntilDestroyed(this.destroyRef)));
  }

  async signIn(email: string, password: string): Promise<void> {
    try {
      await firstValueFrom(
        this.http.post('api/users/sign-in', { email, password }).pipe(takeUntilDestroyed(this.destroyRef)),
      );
    } catch (e) {
      this.handleError(e);
    }
  }

  async signUp(email: string, password: string): Promise<void> {
    try {
      await firstValueFrom(
        this.http.post('api/users/sign-up', { email, password }).pipe(takeUntilDestroyed(this.destroyRef)),
      );
    } catch (e) {
      this.handleError(e);
    }
  }

  async saveCurrentUserProfile(profile: UserProfile): Promise<UserEntity> {
    const dto = await firstValueFrom(
      this.http.put<UserDto>('api/users/current/profile', profile).pipe(takeUntilDestroyed(this.destroyRef)),
    );

    return new UserEntity(
      dto.id,
      dto.email,
      dto.displayName,
      dto.lang,
      dto.isEmailVerified,
      dto.areCurrentTermsAccepted,
      dto.permissions,
      dto.firstname,
      dto.lastname,
    );
  }

  async setNewPassword(password: string): Promise<void> {
    try {
      await firstValueFrom(
        this.http.put('api/users/current/password', { password }).pipe(takeUntilDestroyed(this.destroyRef)),
      );
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        if (e.status === 422) {
          throw new UserException('password-too-short');
        }
      }
    }
  }

  private handleError(e: unknown) {
    if (e instanceof HttpErrorResponse) {
      switch (e.status) {
        case 404:
          throw new UserException('user-not-found');
        case 401:
          throw new UserException('bad-credentials');
        case 409:
          throw new UserException('email-already-used');
        case 422:
          throw new UserException('password-too-short');
        default:
          throw new GenericException('unknown-error');
      }
    }
  }
}
